export default {

	methods: {

    getContactosAsignados ( ){
      const payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final:  this.fecha_final,
        escuela:      this.escuela
      }
      this.$http.post('data.dashboard.marketing', payload).then((response) => {
        this.contactosVendedora  = response.body.planteles
        return
      }).catch(error=>{
        this.cargar = false
      })
    },

    /****************************/
    /****    	 CATALGOS      ****/
    /****************************/
    getComoNosConocio () {
      this.medios_contacto = []
      this.cargar = true
      return this.$http.get('mediocontacto.activas').then(response=>{
      	this.medios_contacto = response.body
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    getFuntes () {
      this.fuentes = []
      this.cargar = true
      return this.$http.get('fuentes.activos').then(response=>{
      	this.fuentes = response.body.filter( el => { return el.vendedora == 1 })
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },
    
    getDetallesFuente () {
      this.detalles = []
      this.cargar = true
      return this.$http.get('fuentes.detalle.activos').then(response=>{
      	this.detalles = response.body
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    // Obtener las campañas
    getCampanias(){
      this.cargar = true
      this.campanias  = []
      return this.$http.get('campanias.activos').then((response) => {
        // Llenamos la etapa incial
        this.campanias = response.body
        this.campania  = this.campanias[0]
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    // obtener las vendedoras
    getVendedoras() {
      this.cargar = true
      this.vendedoras  = []
      return this.$http.get('vendedoras.list').then((response) => {
        // Llenamos la etapa incial
        this.vendedoras = response.body
        this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.escuela})
        this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA'})
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    getCursosEscuela() {
      this.cargar = true
      this.cursos_escuela  = []
      return this.$http.get('escuela.cursos.activos').then((response) => {
        // Llenamos la etapa incial
        this.cursos_escuela = response.body.filter( el=> { return el.escuela == this.escuela })
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    /****************************/
    /****   	FUNCIONES      ****/
    /****************************/

    preLlenarDatos ( item ) {
    	this.prospecto.nombre_completo = item.nombre_completo
    	this.prospecto.idleds          = item.idleds
    	this.dialogNuevoProspecto      = true
    },
    
    addContacto ( lead , idleds ) {
      console.log( lead )
      // Sacamos a la vendedora
      let vendedora = 0
      if(this.editedItem.sucursal.idplantel != 0){
        vendedora = this.vendedoras_plantel.find(el=> el.idplantel == this.editedItem.sucursal.idplantel)
      }
      console.log( 0, vendedora, idleds )
      //recibe los campos del form
      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
      var payload = {
        nombre_completo:  lead.nombre_completo,
        telefono:         lead.telefono,
        correo:           '',
        sucursal_interes: this.editedItem.sucursal.idplantel,
        usuario_creo:     this.datos_usuario.iderp,
        usuario_asignado: vendedora == 0 ? vendedora : vendedora.iderp,
        nota_inicial:     lead.nota_inicial,
        folio:            '',
        acronimo:         this.editedItem.sucursal.idplantel == 0 ? '' : this.editedItem.sucursal.acronimo,
        escuela:          this.escuela,
        idpuesto:         this.datos_usuario.idpuesto,
        como_llego:       0,
        idleds:           idleds
      }
      this.cargar = true

      this.$http.post('prospectos.add', payload).then(response  => {
        this.cargar = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.sucursal  = null
        this.anuncio   = null
        this.vendedora = null
        this.validarSuccess( 'Contacto generado correctamente' )
        this.cargar      = false
      }).catch( error =>{
        this.eliminarLead( idleds )
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    eliminarLead( idleds ){
      this.$http.delete('leds.delete/' + idleds ).then(response  => {
        this.initialize();
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

		save ( item ) {
    	if(!this.editedItem.idfuentes){
    		return this.validarErrorDirecto( 'Favor de llenar todos los campos' )
    	}

      // Ponerle pendiente a su nombre completo
      if(this.editedItem.nombre_completo == ''){ this.editedItem.nombre_completo = 'Pendiente' }

    	// Activamos el loader
      this.cargar = true
      // Lo mandapos por el EP
      this.$http.post('leds.add', this.editedItem).then(response=>{
        if( this.editedItem.telefono ){
          this.addContacto( this.editedItem , response.body.id )
        }else{
        	this.validarSuccess( 'Lead agregado correctamente ')
        }
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    deleteItemConfirm () {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1
      this.cargar = true
      this.$http.put('fuentes.update/' + this.editedItem.idfuentes, this.editedItem).then(response=>{
      	this.dialogSuccess = true
      	this.cargar = false
      	this.closeDelete()
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { this.cargar = false })
    },

    buscarTelefono( value ){
      if( value ||  this.searchTelefono){
        this.cargar = true

        const payload = {
          telefono: value ? value : this.searchTelefono,
          escuela: this.escuela
        }

        this.$http.post('leds.telefono', payload).then( response => {
          this.validarSuccess( response.body.message )
        }).catch( error => {
          this.cargar = false
          alert(`No existe :p`)
        }).finally( ()=> { this.cargar = false })
      }
    },

    cerrarActualizar( ){
      this.editedItem    = Object.assign({}, this.defaultItem)
      this.dialogEditar  = false
    }

  }
}