import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: '',
  base: process.env.BASE_URL,
  routes: [
    { path: '/:id'               , name: 'Home'           , component: Home, 
      meta: { ADMIN: true, USUARIO: true, NORMAL: true, libre: true }},
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.login.datosUsuario) {
    if (to.matched.some(record => record.meta.ADMIN)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})

export default router