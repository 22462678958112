<template>
	<div>
		<v-card>
      <v-card-title>
        <v-card width="80" class="elevation-0 transparent">
          <v-img 
          	v-if="escuela == 2"
            src="@/assets/logo_fast.png"
          ></v-img>

          <v-img 
          	v-else
            src="@/assets/logo_inbi.png"
          ></v-img>

        </v-card>
        <v-spacer></v-spacer>
        <span class="text-subtitle-1 font-weight-bold text-center">
          Nuevo prospecto
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="editedItem.nombre_completo"
                label="Nombre completo"
                filled
                hide-details
                dense
                autofocus
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idmedio_contacto"
                :items="medios_contacto"
                label="¿Cómo nos conocio?"
                persistent-hint
                item-value="idmedio_contacto"
                item-text="medio"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idfuentes"
                :items="fuentes"
                label="¿Cómo nos contactó?"
                item-value="idfuentes"
                item-text="fuente"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.iddetalle_fuentes"
                :items="detallesFilter"
                label="Detalle"
                persistent-hint
                item-value="iddetalle_fuentes"
                item-text="detalle_fuente"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-select
                :items="cursos_escuela"
                v-model="editedItem.idcursos_escuela"
                item-text="curso"
                item-value="idcursos_escuela"
                label="Curso interes"
                hide-details
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-switch label="Foraneo" v-model="editedItem.foraneo" hide-details></v-switch>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-subheader class="mb-0 pb-0 mt-0">Información de contacto</v-subheader>
            </v-col>


            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                v-model="editedItem.telefono"
                label="Teléfono"
                filled
                hide-details
                type="number"
                dense
                @keyup.enter="buscarTelefono( editedItem.telefono )"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pb-0">
              <v-select
                :items="vendedoras_plantel"
                v-model="editedItem.sucursal"
                return-object
                item-text="plantel"
                item-value="idplantel"
                label="Sucursal"
                hide-details
                filled
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-textarea
                v-model="editedItem.nota_inicial"
                filled
                dense
                rows="3"
                hide-details
                label="Nota inicial"
              > 
              </v-textarea>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="red"
          dark
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          class="elevation-6"
          dark
          @click="save"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
	</div>
</template>
<script>
	export default {
		props:[
			'editedItem',
			'cursos_escuela',
			'vendedoras_plantel',
			'fuentes',
			'detallesFilter',
			'medios_contacto',
			'campaniasFilter',
			'escuela',
      'datos_usuario'
	  ],

	  methods:{
		  save( ){
		  	this.$emit('save', this.editedItem )
		  },

		  close( ){
		  	this.$emit('close', this.editedItem )
		  },

		  buscarTelefono( telefono ){
		  	this.$emit('buscarTelefono', telefono )
		  }

	  }
	}
</script>