<template>
	<v-card>
    <v-toolbar
      dark
      color="primary"
      class="elevation-1"
      dense
    >
      <v-toolbar-title>Nuevo prospecto</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-row>
        <v-col cols="12" sm="4" md="4" lg="2">
          <v-img src="@/assets/logo_fast.png" max-width="200" v-if="escuela == 2"></v-img>
          <v-img src="@/assets/logo_inbi.png" max-width="200" v-else></v-img>
        </v-col>

        <v-col cols="12" sm="8" md="8" lg="10">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  v-model="prospecto.nombre_completo"
                  label="Nombre completo"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.number="prospecto.telefono"
                  label="Telefono"
                  outlined
                  dense
                  type="number"
                  :counter="10"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  v-model="prospecto.email"
                  label="Email"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="8" md="6" lg="4">
                <v-select
                  :items="vendedoras_plantel"
                  v-model="editedItem.sucursal"
                  return-object
                  item-text="plantel"
                  item-value="idplantel"
                  label="Sucursal"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="prospecto.nota_inicial"
                  outlined
                  dense
                  rows="3"
                  hide-details
                  label="Nota inicial"
                > 
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-btn color="red" dark @click="cerarDialog()">
          Cerrar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="grabarProspecto()" v-if="!cargar">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
	export default {
		props:[
			'prospecto',
			'editedItem',
			'vendedoras_plantel',
			'escuela',
			'cargar'
	  ],

	  methods:{
		  grabarProspecto( ){
		  	this.$emit('grabarProspecto' )
		  },

		  cerarDialog( ){
		  	this.$emit('cerarDialog')		  	
		  }
	  }
	}
</script>