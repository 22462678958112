<template>
	<v-card>
    <v-card-text>
      <v-row>
        <v-col cols="7">
          <v-card class="elevation-0" height="100%" style="display: table;">
            <div class="" style="display: table-cell; vertical-align: middle;">
              <strong v-if="escuela == 2">¿Estas seguro que este prospescto es de FAST?</strong>
              <strong v-else>¿Estas seguro que este prospescto es de INBI?</strong>
              
              <v-card-actions class="pt-6">
                <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                <v-btn color="error" text large class="mr-2" @click="dialogValidarEscuela = false">NOO</v-btn>
                <!-- Guardar la información  -->
                <v-btn color="#D60F24" dark class="elevation-6" large @click="escuelaValidada()">SIII</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
        <v-col cols="5" class="pa-10">
          <v-img src="@/assets/logo_fast.png" v-if="escuela == 2"></v-img>
          <v-img src="@/assets/logo_inbi.png" v-else></v-img>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
	export default {
		props:[
			'escuela',
	  ],

	  methods:{
		  escuelaValidada( ){
		  	this.$emit('escuelaValidada' )
		  },
	  }
	}
</script>