<template>
  <v-app id="back">

    <v-main >
      <v-container fill-height >
        <v-row justify="center">
          <v-col cols="12" md="10" lg="7">
            <nuevo_lead 
              :editedItem="editedItem"
              :cursos_escuela="cursos_escuela"
              :vendedoras_plantel="vendedoras_plantel"
              :fuentes="fuentes"
              :detallesFilter="detallesFilter"
              :medios_contacto="medios_contacto"
              :campaniasFilter="campaniasFilter"
              :escuela="escuela"
              :datos_usuario="datos_usuario"
              @save="save"
              @close="close"
              @buscarTelefono="buscarTelefono"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-app>
</template>

<script>
  const axios = require('axios');
  import { mapGetters, mapActions } from 'vuex';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import nuevo_lead            from '@/components/leads/nuevo_lead.vue';
  import nuevo_prospecto       from '@/components/leads/nuevo_prospecto.vue';
  import validar_escuela       from '@/components/leads/validar_escuela.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import mixinLeads            from '@/mixins/mixinLeads'

  export default {
    components:{
      Alerta,
      carga,
      nuevo_lead,
      nuevo_prospecto,
      validar_escuela,
    },

    mixins: [ validarErrores, mixinLeads ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      valid: true,
      nameRules: [
        v => !!v || 'Teléfono es requerido',
        v => (v && !(v < 1000000000 )) || 'No son 10 números',
      ],

      datos_usuario:null,

      ///usuarios
      dialog: false,
      // Modales
      dialogDelete: false,
      dialogNuevoProspecto: false,
      dialogValidarEscuela: false,
      dialogEditar:false,

      
      prospectos: [], //arreglo
      editedIndex: -1,
      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 2,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
      },
      defaultItem: {
        nombre_completo:'',
        idfuentes: '',
        idleds:'',
        escuela: 2,
        iddetalle_fuentes:0,
        fuente: 0,
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
      },
      // Datos del prospecto
      prospecto: {
        //agrego cada campo a utlizar
        idprospectos: 0,
        nombre_completo: '',
        telefono:'',
        correo: '',
        idetapa:0,
        sucursal_interes:0,
        como_nos_conocio:0,
        ciclo_interes: 0,
        curso_interes:0,
        usuario_asignado:0,
        usuario_creo:0,
        nota_inicial:'',
        folio:'',
        idleds:0,
      },

      etapas:[],
      etapa:null,
      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
     
      escuela: 2,

      habilAgregar: false,

      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,

      fechaini:null,
      fechafin:null,

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',
      // Consultas extras
      detalles:[],
      fuentes:[]

    }),

    watch:{
      nuevaSucursal(){
        this.nombreVendedora = this.nuevaSucursal.nombre
      }
    },

    computed: {
      filterSucursales(){
        if(this.sucursales){
          return this.sucursales.filter( el=> { return el.escuela == this.escuela})
        }
      },

      filterVendedoras(){
        return this.vendedoras.filter( el=> { return el.escuela == this.escuela})
      },

      detallesFilter(){
        return this.detalles.filter(el => { return el.idfuentes == this.editedItem.idfuentes })
      },

      campaniasFilter(){
        return this.campanias.filter(el => { return el.idmedio_contacto == this.editedItem.idmedio_contacto })
      },
    },

    async created() {
      this.habilAgregar         = false
      this.dialogNuevoProspecto = false

      if(this.$route.params.id){
        this.cargar = true
        this.idusuario = this.$route.params.id
        await this.getUsuario()
        await this.getSucursales()
        await this.getVendedoras()

        await this.getFuntes()
        await this.getDetallesFuente()
        await this.getCampanias()
        await this.getCursosEscuela()
        await this.getComoNosConocio()
        this.habilAgregar         = true
        this.dialogNuevoProspecto = true

        this.cargar = false
      }else{
        this.validarErrorDirecto('Sin acceso')
      }
    },

    methods: {
      getUsuario(){
        this.$http.get('usuarios.id/' + this.idusuario).then((response) => {
          this.datos_usuario = response.body
          console.log( this.datos_usuario )
        }).catch((error) => {
          // this.salirLogin()
        });
      },
      // Obtener las campañas
      getCampanias(){
        this.cargar = true
        this.campanias  = []
        return this.$http.get('campanias.activos').then((response) => {
          // Llenamos la etapa incial
          this.campanias = response.body
          this.campania  = this.campanias[0]
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      // Obtener todas las sucursales
      getSucursales() {
        this.cargar = true
        this.sucursales  = []
        return this.$http.get('planteles.all').then((response) => {
          // Llenamos la etapa incial
          this.sucursales = response.body
          this.sucursal   = response.body[0]
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      getVendedoras() {
        this.cargar = true
        this.vendedoras  = []
        return this.$http.get('vendedoras.list').then((response) => {
          // Llenamos la etapa incial
          this.vendedoras = response.body
          this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.escuela})
          // this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA'})
          this.cargar = false
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },
      
      // Datos para grabar el prospecto
      grabarProspecto() {
        if(!this.$refs.form.validate())
          return


        this.cargar = true 
        // Sacamos ala vendedora
        const vendedora = this.vendedoras_plantel.find(el=> el.idplantel == this.editedItem.sucursal_interes)

        // Llenamos los campos faltantes
        this.editedItem['usuario_creo']     = this.datos_usuario.iderp
        this.editedItem['idpuesto']         = this.datos_usuario.idpuesto
        this.editedItem['como_llego']       = this.datos_usuario.idpuesto == 25 ? this.campania.idcampanias : 0
        this.editedItem['escuela']          = this.escuela
        this.editedItem['acronimo']         = vendedora.acronimo
        this.editedItem['usuario_asignado'] = vendedora.iderp

        this.$http.post('prospectos.add', this.editedItem).then((response) => {
          this.cargar       = false
          this.close()
          this.dialogSuccess = true
          this.initialize();
        }).catch( error =>{
          this.validarError( error )
        }).finally( () => { this.cargar = false })
      },

      close() {
        this.dialog = false;
        this.editedItem = {
          idprospectos: 0,
          nombre_completo: '',
          telefono: '',
          correo: '',
          idetapa:2,
          sucursal_interes:0,
          usuario_asignado:0,
          usuario_creo:0,
          nota_inicial:'',
          folio:''
        }
      },

      limpiar ( ) {
        this.etapa     = null
        this.sucursal  = null
        this.anuncio   = null
        this.curso     = null
        this.ciclo     = null
        this.vendedora = null
      },

    },
  };
</script>
<style scoped>

  #back{
    background-color: #EEEEEE;
  }

  #menuable__content__active::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #menuable__content__active::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #menuable__content__active::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

</style>

