import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import colors from 'vuetify/es5/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
	lang:{
    locales:{ es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken1, // #E53935
        secondary: colors.blue.darken3, // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
        error:  colors.red.darken1,
        info:   colors.orange,
        success: colors.green.darken2,
        warning :  colors.teal
      },
    },
  },
});
