import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

export default {

	data: () => ({
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,
  }),
  
	methods: {
		validarrespuestaAlerta( error ){ 
			return new Promise( (resolve, reject) => {
				if( error.status == 400){
					resolve( error.body.message )
				}else{
					reject( error )
				}
			})
		},


		consultar_niveles_usuario(){
			return new Promise( (resolve) => {
				this.$http.get('niveles.activas').then((response)=>{
					console.log(response.body)
					resolve(response.body)
				}).catch(error =>{
					console.log('error niveles', error)
				})
			})
		},

		validarSuccess( mensaje ){
      this.cargar = false
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = 'success'
      this.respuestaAlerta         = true 
    },

    validarError ( value ) {
      this.cargar = false 
      if( value.status == 400 || value.status == 500){
        this.parametros.mensaje      = value.body.message
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
      }
    },

    validarErrorDirecto ( mensaje ) {
      this.cargar = false 
      this.parametros.mensaje      = mensaje
      this.parametros.dialogError  = true 
      this.parametros.color        = '#FF2C2C'
      this.respuestaAlerta = true 
    },
  }
}